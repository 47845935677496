(function () {
	let isInitialized = false;

	window.addEventListener("DOMContentLoaded", () => {
		if (!isInitialized) {
			isInitialized = true;
			init();
		}
	});

	const init = () => {
		const els = document.querySelectorAll(".js-segment-group");
		[...els].forEach(setupSegmentGroup);
	};

	const setupSegmentGroup = (el) => {
		//
	};
})();
